<template>
  <div class="container-edit-user">
      <b-card>
          <b-row>
              <b-col cols="12">
                  <h5>{{  basicInfo.login_id  }}</h5>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12">
                  <h5>{{  basicInfo.user_name  }}</h5>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12">
                  <h5>{{  basicInfo.user_tel  }}</h5>
              </b-col>
          </b-row>
          <b-row class="mt-1">
              <b-col cols="12">
                  <b-button-toolbar>
                      <b-button variant="primary" class="mr-2" @click="beforeLoginUser()"
                          :disabled="disabledBtnProxyLogin">
                          代理ログイン
                      </b-button>
                      <b-button variant="primary" @click="dialogPW = true">
                          PW再発行
                      </b-button>
                  </b-button-toolbar>
              </b-col>
          </b-row>
      </b-card>
      <b-card>
          <validation-observer ref="simpleRules">
              <b-form>
                  <b-tabs content-class="pt-1" fill>

                      <!-- Basic Information -->
                      <b-tab title="基本情報" key="0">
                          <b-form>
                              <b-row>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="ログインID">
                                          <template #label>
                                              <div class="required-label">
                                                  <span class="label">ログインID</span>
                                                  <span class="required">必須</span>
                                              </div>
                                          </template>
                                          <validation-provider #default="{ errors }" vid="login_id" rules=""
                                              name="ログインID">
                                              <b-form-input v-model="basicInfo.login_id"
                                                  :state="errors.length > 0 ? false : null" />
                                              <small class="text-danger">{{  errors[0]  }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="利用者名">
                                          <validation-provider #default="{ errors }" vid="user_name" rules=""
                                              name="利用者名">
                                              <b-form-input v-model="basicInfo.user_name"
                                                  :state="errors.length > 0 ? false : null" />
                                              <small class="text-danger">{{  errors[0]  }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="利用者名(カナ)">
                                          <validation-provider #default="{ errors }" vid="user_name_kana" rules=""
                                              name="利用者名(カナ)">
                                              <b-form-input v-model="basicInfo.user_name_kana"
                                                  :state="errors.length > 0 ? false : null" />
                                              <small class="text-danger">{{  errors[0]  }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="電話番号">
                                          <validation-provider #default="{ errors }" vid="user_tel" rules=""
                                              name="電話番号">
                                              <b-form-input v-model="basicInfo.user_tel"
                                                  :state="errors.length > 0 ? false : null" />
                                              <small class="text-danger">{{  errors[0]  }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="携帯電話">
                                          <template #label>
                                              <div class="required-label">
                                                  <span class="label">携帯電話</span>
                                              </div>
                                          </template>
                                          <validation-provider #default="{ errors }" vid="user_mobile" rules="max:11"
                                              name="携帯電話">
                                              <b-form-input v-model="basicInfo.user_mobile"
                                                  :state="errors.length > 0 ? false : null" />
                                              <small class="text-danger">{{  errors[0]  }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="登録者">
                                          <b-form-input v-model="basicInfo.strRegisterPerson" readonly />
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="登録日時">
                                          <b-form-input v-model="basicInfo.strRegDatetime" readonly />
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="更新者">
                                          <b-form-input v-model="basicInfo.strUpdatePerson" readonly />
                                      </b-form-group>
                                  </b-col>
                                  <b-col cols="12">
                                      <b-form-group label-cols="4" label-cols-lg="2" label="更新日時">
                                          <b-form-input v-model="basicInfo.strUpdDatetime" readonly />
                                      </b-form-group>
                                  </b-col>
                              </b-row>
                          </b-form>
                      </b-tab>
                      <!-- End of Basic Information -->

                      <!-- Account Information List -->
                      <b-tab v-for="(accountInfo, index) in accountInfoList" :key="index + 1"
                          :title="'所属ログイン先 ' + ++index">
                          <b-row>
                              <h5 class="font-weight-bold ml-1 mb-2">所属ログイン先 {{  index  }}</h5>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="得意先">
                                      <template #label>
                                          <div class="required-label">
                                              <span class="label">得意先</span>
                                              <span class="required">必須</span>
                                          </div>
                                      </template>
                                      <validation-provider #default="{ errors }"
                                          :vid="'listAffiliationLoginDestination.' + [index - 1] + '.totocd'"
                                          rules="required" name="得意先">
                                          <v-select v-model="accountInfo.strCustomerInformation" label="item_data"
                                              :options="paginated" :state="errors.length > 0 ? false : null"
                                              @input="changeEvent(index - 1)" :filterable="false" @open="onOpen(index)"
                                              @close="onClose" @search="(query) => (search = query)">
                                              <template #no-options>
                                                入力された営業コードは、存在しません。
                                              </template>
                                              <template #list-footer>
                                                  <li v-show="hasNextPage" ref="loadUserUpdate" class="loader">
                                                  Loading more options...
                                                  </li>
                                              </template>
                                          </v-select>
                                          <small class="text-danger">{{  errors[0]  }}</small>
                                      </validation-provider>
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="事務所電話番号">
                                      <b-form-input v-model="accountInfo.totel" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="工場電話番号">
                                      <b-form-input v-model="accountInfo.toktel" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="自宅電話番号">
                                      <b-form-input v-model="accountInfo.tomtel" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="役職">
                                      <validation-provider #default="{ errors }"
                                          :vid="'listAffiliationLoginDestination.' + [index - 1] + '.user_yakushoku'"
                                          rules="" name="役職">
                                          <b-form-input v-model="accountInfo.user_yakushoku"
                                              :state="errors.length > 0 ? false : null" />
                                          <small class="text-danger">{{  errors[0]  }}</small>
                                      </validation-provider>
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="アカウント種別">
                                      <template #label>
                                          <div class="required-label">
                                              <span class="label">アカウント種別</span>
                                              <span class="required">必須</span>
                                          </div>
                                      </template>
                                      <b-form-select v-model="accountInfo.user_role" :options="role" />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="営業所">
                                      <b-form-input v-model="accountInfo.jename" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="営業担当">
                                      <b-form-input v-model="accountInfo.einame" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="最終ログイン日時">
                                      <b-form-input v-model="accountInfo.strLastLoginDatetime" readonly />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="ステータス">
                                      <template #label>
                                          <div class="required-label">
                                              <span class="label">ステータス</span>
                                              <span class="required">必須</span>
                                          </div>
                                      </template>
                                      <b-form-select v-model="accountInfo.user_status" :options="user_status">
                                      </b-form-select>
                                  </b-form-group>
                              </b-col>
                              <b-col cols="12">
                                  <b-form-group label-cols="4" label-cols-lg="2" label="価格閲覧許可">
                                      <template #label>
                                          <div class="required-label">
                                              <span class="label">価格閲覧許可</span>
                                              <span class="required">必須</span>
                                          </div>
                                      </template>
                                      <b-form-select v-model="accountInfo.kakaku_flg" :options="kakaku_flg">
                                      </b-form-select>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                      </b-tab>
                      <!-- End of Account Information List -->

                  </b-tabs>
              </b-form>
          </validation-observer>
      </b-card>
      <b-card>
          <!-- Handle redirect to edit screen -->
          <b-row class="text-center" align-v="center">
              <b-col cols="12">
                  <b-button variant="danger" class="mr-5" @click="detailUser">
                      キャンセル
                  </b-button>
                  <b-button type="submit" variant="primary" class="ml-5" @click="validationForm">
                      変更
                  </b-button>
              </b-col>
              <b-col>

              </b-col>
          </b-row>
          <!-- End of Handle redirect to edit screen -->
      </b-card>
      <Dialog :dialog.sync="dialogLogin" :title="'代理ログイン'" :btnOk="'OK'" :btnCancel="'キャンセル'" @submit="loginUser">
          <template slot="content">
              <span v-if="this.loginFlag">現在ユーザーがログインしています。</span>
              ログインしますか？
          </template>
      </Dialog>
      <Dialog :dialog.sync="dialogPW" :title="'PW再設定'" :btnOk="'OK'" :btnCancel="'キャンセル'"
          @submit="sendEmailResetPassword">
          <template slot="content">
              PW再設定メールを送信しますか？
          </template>
      </Dialog>
      <Dialog :dialog.sync="dialogEdit" :title="'ユーザー情報の変更'" :btnOk="'変更'" :btnCancel="'キャンセル'" @submit="submitForm">
          <template slot="content">
              ユーザー情報の変更を行ってよろしいですか？
          </template>
      </Dialog>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormSelect } from "bootstrap-vue";
import { BCard, BRow, BCol } from "bootstrap-vue";
import Dialog from "@core/components/dialog/Dialog";
import vSelect from 'vue-select'
import { apiService } from "@/services/api.service";
import { required } from '@validations'
import {
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { BTab, BTabs } from "bootstrap-vue";
import { BForm, BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  components: {
      ValidationProvider,
      ValidationObserver,
      BCard,
      BRow,
      BCol,
      Dialog,
      BFormSelect,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BDropdown,
      BDropdownItem,
      BTab,
      BTabs,
      BForm,
      BFormGroup,
      BFormInput,
      vSelect
  },
  data() {
      return {
          tabIndex: 0,
          dialogEdit: false,
          dialogStatus: false,
          dialogPW: false,
          dialogLogin: false,
          user_status: [],
          changeUserStatus: "",
          kakaku_flg: [],
          disabledBtnProxyLogin: true,
          role: [],
          basicInfo: {
              login_id: "",
              user_name: "",
              user_name_kana: "",
              user_tel: "",
              user_mobile: "",
              strRegisterPerson: "",
              strRegDatetime: "",
              strUpdatePerson: "",
              strUpdDatetime: "",
          },
          accountInfoList: [
              {
                  login_key: "",
                  totocd: "",
                  tojicd: "",
                  jeegcd: "",
                  user_role: "",
                  user_status: "",
                  user_yakushoku: "",
                  kakaku_flg: "",
                  mitumori_irai_mail_flg: "",
                  mitumori_kanryo_mail_flg: "",
                  order_torikesi_mail_flg: "",
                  toiawase_kanryo_mail_flg: "",
                  toiawase_kaito_mail_flg: "",
                  sekisan_mitumori_irai_mail_flg: "",
                  sekisan_mitumori_iraikanryo_mail_flg: "",
                  sekisan_irai_mail_flg: "",
                  sekisan_iraikanryo_mail_flg: "",
                  chumon_kanryo_mail_flg: "",
                  shukkayotei_mail_flg: "",
                  shukkakanryo_mail_flg: "",
                  last_login_datetime: "",
                  strCustomerInformation: "",
                  totel: "",
                  toktel: "",
                  tomtel: "",
                  jename: "",
                  einame: "",
                  hashedUserId: "",
                  basicInformation: {
                      user_id: "",
                      login_id: "",
                      user_name: "",
                      user_name_kana: "",
                      user_tel: "",
                      user_mobile: "",
                      reg_user_id: "",
                      reg_datetime: "",
                      upd_id: "",
                      upd_datetime: "",
                      user_status: "",
                      strRegisterPerson: "",
                      strRegDatetime: "",
                      strUpdatePerson: "",
                      strUpdDatetime: "",
                  },
              },
          ],
          selected: [],
          tonam1: '',
          tonam2: '',
          option: [],
          tempStatus: '',

          // User login flag - get from API
          loginFlag: false,
          //Infinity scroll
          observer: null,
          limit: 50,
          search: ''
      };
  },
  mounted() {
      this.getUserStatus();
      this.getListTokui();
      this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created() {
      this.getData();
  },
  computed: {
      filtered() {
          return this.option.filter((item) => item.item_data.includes(this.search))
      },
      paginated() {
          return this.filtered.slice(0, this.limit)
      },
      hasNextPage() {
          return this.paginated.length < this.filtered.length
      },
},
  methods: {
      async onOpen(index) {
          if (this.hasNextPage) {
              await this.$nextTick()
              this.observer.observe(this.$refs.loadUserUpdate[index - 1])
          }
      },
      onClose() {
          this.observer.disconnect()
      },
      async infiniteScroll([{ isIntersecting, target }]) {
          if (isIntersecting) {
              const ul = target.offsetParent
              const scrollTop = target.offsetParent.scrollTop
              this.limit += 50
              await this.$nextTick()
              ul.scrollTop = scrollTop
          }
      },
      validationForm() {
          this.$refs.simpleRules.validate().then(success => {
              if (success) {
                  // eslint-disable-next-line
                  this.dialogEdit = true;
              }
          })
      },
      changeEvent(ind) {
          this.accountInfoList[ind].totel = this.accountInfoList[ind].strCustomerInformation.totel;
          this.accountInfoList[ind].toktel = this.accountInfoList[ind].strCustomerInformation.toktel;
          this.accountInfoList[ind].tomtel = this.accountInfoList[ind].strCustomerInformation.tomtel;
          this.accountInfoList[ind].jename = this.accountInfoList[ind].strCustomerInformation.jename;
          this.accountInfoList[ind].einame = this.accountInfoList[ind].strCustomerInformation.einame;
      },
      async submitForm() {
          let listAffiliationLoginDestination = this.accountInfoList.map(ele => {
              let tempStrCustomerInformation = '';
              if (ele.strCustomerInformation.item_data) {
                  tempStrCustomerInformation = ele.strCustomerInformation.item_data;
              } else {
                  tempStrCustomerInformation = ele.strCustomerInformation;
              }
              const tempList = tempStrCustomerInformation.split("-");
              const tempTotocd = tempList[2].split(" ", 1)

              return {
                  login_key: ele.login_key,
                  totocd: tempTotocd[0],
                  tojicd: tempList[0],
                  jeegcd: tempList[1],
                  user_role: ele.user_role,
                  user_yakushoku: ele.user_yakushoku,
                  kakaku_flg: ele.kakaku_flg,
                  user_status: ele.user_status
              }
          })
          let formData = {
              user_id: this.basicInfo.user_id,
              login_id: this.basicInfo.login_id,
              user_name: this.basicInfo.user_name,
              user_name_kana: this.basicInfo.user_name_kana,
              user_tel: this.basicInfo.user_tel,
              user_mobile: this.basicInfo.user_mobile,
              listAffiliationLoginDestination: listAffiliationLoginDestination,
          };

          try {
              this.startLoading();
              let res = await apiService.put(`user/edit/` + this.$route.params.id, formData);
              if (res.status == 200) {
                  this.endLoading();
                  this.notifySuccess(res.data.message);
                  this.$router.push({ name: 'user-list' })
              }
          } catch (error) {
              this.endLoading();
              if (error.data.code == 422) {
                  this.$refs.simpleRules.setErrors(error.data.data);
              }
              else if (e.data.message != '') {
                  this.notifyError(e.data.message)
              } else {
                  this.notifyError("error")
              }
          }
      },
      async getData() {
          this.startLoading();
          await apiService
              .get(`user/show/` + this.$route.params.id)
              .then(async (response) => {
                  this.basicInfo = response.data.data[0].basicInformation;
                  this.accountInfoList = response.data.data;
                  if (!response.data.data[0].acceptAction) {
                      await this.$router.push({ name: "user-list" });
                      this.endLoading();
                  }
                  let disabledBtnProxy = this.accountInfoList.find(ele => ele.user_status == 1);
                  if (disabledBtnProxy) {
                      this.disabledBtnProxyLogin = false
                  }
                  this.endLoading();
              })
              .catch((e) => {
                  this.endLoading();
                  this.$router.push({ name: "error-404" });
              });
      },
      async getListTokui() {
          const res = await apiService.get(`user/get-fields-data`);
          if (res.data.code == 200)
              this.option = res.data.data.listOfCustomerMaster;
          this.option = this.option.map(x => {
              x.item_data = `${this.formatCustomerMaster(x.tojicd, x.toegcd, x.totocd)} ${x.tonam1}${x.tonam2}`
              return x;
          });
      },
      getUserStatus: function () {
          this.changeUserStatus = this.accountInfoList[0].user_status ?? "";
      },

      async beforeLoginUser() {
          this.startLoading();
          await apiService
              .get(`user/get-login-flag/` + this.basicInfo.hashedUserId)
              .then((response) => {
                  this.endLoading();
                  let self = this;
                  this.loginFlag = response.data.data.loginFlag || self.loginFlag;
                  this.dialogLogin = true
              })
              .catch((e) => {
                  this.endLoading();
                  this.notifyError(e.data.message || 'SERVER_ERROR');
              });
      },
      async loginUser() {
          this.startLoading();
          await apiService
              .get(`user/get-login-token/` + this.$route.params.id)
              .then((response) => {
                  this.endLoading();
                  localStorage.removeItem('auth._token.local')
                  window.open(
                      process.env.VUE_APP_USER_URL + `choose-company-by-admin/order-product/` + response.data.data.loginToken,
                      '_blank'
                  );
              })
              .catch((e) => {
                  this.endLoading();
                  if (e.data.message != '') {
                      this.notifyError(e.data.message)
                  } else {
                      this.notifyError("error")
                  }
              });
      },
      async sendEmailResetPassword() {
          this.startLoading();
          await apiService
              .post(`user/send-email-reset-password`, {
                  login_id: this.basicInfo.login_id,
              })
              .then((response) => {
                  this.endLoading();
                  this.notifySuccess(response.data.message);
              })
              .catch((e) => {
                  this.endLoading();
                  if (e.data.message != '') {
                      this.notifyError(e.data.message)
                  } else {
                      this.notifyError("error")
                  }
              });
      },
      detailUser() {
          this.$router.push({
              name: "user-detail",
              params: { id: this.$route.params.id },
          });
      },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.required-label {
  display: flex;
  justify-content: space-between;

  .required {
      color: red
  }
}
</style>
<style>
.container-edit-user h5 {
  font-weight: bold !important;
}
.loader {
text-align: center;
color: #bbbbbb;
}
</style>
